(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/swipe-to-select/assets/javascripts/swipe-to-select.js') >= 0) return;  svs.modules.push('/components/lb-ui/swipe-to-select/assets/javascripts/swipe-to-select.js');
"use strict";


const NONE = 0;
const ADDING = 1;
const REMOVING = 2;
const CLICK_ACTIVE = 3;
const TRESHOLD = 5;
const CLASS_HOOK = 'js-swipeable';
const {
  useRef,
  useState,
  useEffect,
  useCallback
} = React;
const SwipeToSelect = _ref => {
  let {
    children,
    onSelect,
    className,
    isSelected,
    identifier
  } = _ref;
  const [editingMode, setEditingMode] = useState(NONE);
  const [forbidClickTimeout, setForbidClickTimeout] = useState(0);
  const [forbidClick, setForbidClick] = useState(false);
  const [isTouchMoveRetrieved, setIsTouchMoveRetrieved] = useState(false);
  const [startX, setStartX] = useState(0);

  const getTargetAtCoords = (clientX, clientY) => {
    const target = document.elementFromPoint(clientX, clientY);
    const element = target && target.classList.contains(CLASS_HOOK) ? target : target && target.parentElement && target.parentElement.classList.contains(CLASS_HOOK) ? target.parentElement : null;
    return element;
  };
  const ref = useRef(null);
  const preventScrollIfTreshold = useCallback(event => {
    editingMode !== NONE && event.preventDefault();
  }, [editingMode]);
  const handleTouchStart = event => {
    setForbidClickTimeout(0);
    if (editingMode !== NONE) {
      return;
    }
    setForbidClick(true);
    const {
      clientX
    } = event.touches[0];
    setStartX(clientX);
    setEditingMode(isSelected ? REMOVING : ADDING);
    const currentRef = ref.current;
    currentRef.addEventListener('touchmove', preventScrollIfTreshold);
  };
  const finishTouchOrClick = function () {
    let forceSelection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    let forcedSelection = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    !isTouchMoveRetrieved && onSelect(identifier, !forceSelection ? editingMode === ADDING : forcedSelection);
    setIsTouchMoveRetrieved(false);
    setStartX(0);
    ref.current.removeEventListener('touchmove', preventScrollIfTreshold);
    requestIdleCallback(() => {
      setEditingMode(NONE);
    });
  };
  const handleTouchMove = event => {
    if (editingMode !== ADDING && editingMode !== REMOVING) {
      return;
    }
    const {
      clientX,
      clientY
    } = event.touches[0];

    if (Math.abs(clientX - startX) > TRESHOLD) {
      if (!isSelected) {
        onSelect(identifier, editingMode === ADDING);
      }
      const targetBtn = getTargetAtCoords(clientX, clientY);
      if (targetBtn) {
        const expectedSelectionMode = isSelected ? REMOVING : ADDING;
        const isThis = identifier === targetBtn.dataset.identifier;
        if (isThis && editingMode === expectedSelectionMode) {
          onSelect(identifier, editingMode === ADDING);
        } else {
          onSelect(targetBtn.dataset.identifier, editingMode === ADDING);
        }
      }
      setIsTouchMoveRetrieved(true);
    }
  };
  const handleTouchEnd = () => {
    editingMode !== CLICK_ACTIVE && finishTouchOrClick();
    setForbidClickTimeout(400);
  };
  const handleClick = () => {
    if (editingMode === NONE && !forbidClick) {
      setEditingMode(CLICK_ACTIVE);
      finishTouchOrClick(true, !isSelected);
    }
  };
  useEffect(() => {
    if (forbidClickTimeout !== 0) {
      setForbidClick(true);
      const timeout = setTimeout(() => {
        setForbidClick(false);
        setForbidClickTimeout(0);
      }, forbidClickTimeout);
      return () => clearTimeout(timeout);
    }
  }, [forbidClickTimeout]);
  return React.createElement("div", {
    className: "".concat("".concat(className ? "".concat(className, " ") : ''), "js-swipeable"),
    "data-identifier": identifier,
    onClick: Boolean(onSelect) && handleClick,
    onTouchEnd: Boolean(onSelect) && handleTouchEnd,
    onTouchMove: Boolean(onSelect) && handleTouchMove,
    onTouchStart: Boolean(onSelect) && handleTouchStart,
    ref: ref
  }, children);
};
setGlobal('svs.components.lbUi.swipeToSelect.SwipeToSelect', SwipeToSelect);

 })(window);